import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-editor-templates",
  templateUrl: "./marketing-portal-editor-templates.component.html",
  styleUrls: ["./marketing-portal-editor-templates.component.scss"],
})
export class MarketingPortalEditorTemplatesComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  public marketingPortalTemplatesPaginationView: any;
  public page: number = 0;
  public itemsPerPage: number = 100;
  public count: number;
  public groupedTemplates: any = [];
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService
  ) {}

  ngOnInit() {
    setTimeout(() => this.appLoader.open());

    this.crudService
      .getAllMPTemplates(this.page, this.itemsPerPage)
      .subscribe((res) => {
        this.marketingPortalTemplatesPaginationView = res;
        this.count = this.marketingPortalTemplatesPaginationView.count;
        this.groupTemplateByCategory();
      });

    setTimeout(() => this.appLoader.close(), 500);
  }

  groupTemplateByCategory() {
    this.marketingPortalTemplatesPaginationView.templates.forEach(
      (template) => {
        let categoryName = template.category.templateCategoryName;

        if (!this.categoryExists(categoryName)) {
          this.groupedTemplates.push({
            category: {
              name: categoryName,
            },
            templates: [],
          });
        }

        let index = this.getGroupedTemplateIndexByCategory(categoryName);
        this.groupedTemplates[index].templates.push(template);
      }
    );
  }

  categoryExists(categoryName) {
    return this.getGroupedTemplateIndexByCategory(categoryName) != -1;
  }

  getGroupedTemplateIndexByCategory(categoryName) {
    return this.groupedTemplates.findIndex(
      (groupedTemplate) => groupedTemplate.category.name === categoryName
    );
  }
}
