import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import {
  SERVICE_ID_MAPADS,
  USER_TYPE_ID_GASTRO,
  USER_TYPE_ID_SERVICE_PROVIDER,
} from "../../../constants";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  menuItems: BehaviorSubject<IMenuItem[]>;
  menuItems$: any;
  defaultMenu: IMenuItem[];
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  constructor() {
    const userTypeId = parseInt(window.localStorage["userTypeId"]);

    this.defaultMenu = [
      {
        name: "DASHBOARD",
        type: "link",
        tooltip: "Dashboard",
        icon: "dashboard",
        state: "mapAds/dashboard/main",
      },
      {
        name: "150",
        type: "link",
        tooltip: "Promomtion-Management",
        icon: "local_offer",
        state: "mapAds/promo",
      },
      {
        name: "STOREMANAGEMENT",
        type: "dropDown",
        tooltip: "STOREMANAGEMENT",
        icon: "store",
        state: "mapAds/store-management",
        sub: [
          { name: "MyStores", state: "" },
          { name: "Groups", state: "groups" },
        ],
      },
      {
        name: "MARKETINGBUDGET",
        type: "dropDown",
        tooltip: "MARKETINGBUDGET",
        icon: "star",
        state: "mapAds/dashboard",
        sub: [
          { name: "MyMarketingBudget", state: "budget/budget-configurator" },
          { name: "Payments", state: "budget/payments" },
        ],
      },
      {
        name: "MARKETINGPORTAL",
        type: "dropDown",
        tooltip: "MARKETINGPORTAL",
        icon: "diamond",
        state: "mapAds/marketing-portal",
        sub: [
          { name: "MyCampaigns", state: "campaigns" },
          { name: "CreateCampaign", state: "campaign/channels" },
        ],
      },
      {
        name: "ACTIVITIES",
        type: "link",
        tooltip: "Activities",
        icon: "history",
        state: "mapAds/activities",
      },
      {
        name: "151", //Statistics
        type: "dropDown",
        tooltip: "Statistiken",
        icon: "equalizer",
        state: "mapAds/dashboard",
        sub: [
          { name: "152", state: "sta" }, //General
          { name: "TVStatistics", state: "sta/tv" }, //TV Statistics
          { name: "SPARKASSE", state: "sta/details/7" }, //Sparkasse
          { name: "DISPLAYNETWORK", state: "sta/details/5" }, //Display
        ],
      },
      {
        name: "SETTINGS", //Statistics
        type: "dropDown",
        tooltip: "Statistiken",
        icon: "settings",
        state: "mapAds/settings",
        sub: [
          { name: "SETTINGSGENERAL", state: "general" }, //General
          { name: "ACCOUNTS", state: "accounts" }, //General
          {
            name: "CONTACT",
            type: "link",
            icon: "contact_support",
            state: "contact",
          },
          {
            name: "FAQ",
            type: "link",
            icon: "question_answer",
            state: "faq",
          },
        ],
      },
    ];

    const statIndex = this.defaultMenu.findIndex(
      (item) => item.name === "151" // STATISTICS
    );

    // Remove google statistics
    if (
      userTypeId == USER_TYPE_ID_SERVICE_PROVIDER ||
      userTypeId == USER_TYPE_ID_GASTRO
    ) {
      const subIndex = this.defaultMenu[statIndex].sub.findIndex(
        (subItem) => subItem.name === "GOOGLE"
      );
      this.defaultMenu[statIndex].sub.splice(subIndex, 1);
    }

    // Remove s-cashback statistics
    if (environment.serviceId == SERVICE_ID_MAPADS) {
      const subIndex = this.defaultMenu[statIndex].sub.findIndex(
        (subItem) => subItem.name == "SPARKASSE"
      );

      this.defaultMenu[statIndex].sub.splice(subIndex, 1);
    }

    this.menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    this.menuItems$ = this.menuItems.asObservable();
  }

  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.defaultMenu);
  }
}
