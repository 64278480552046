import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-campaign-overview",
  templateUrl: "./marketing-portal-campaign-overview.component.html",
  styleUrls: ["./marketing-portal-campaign-overview.component.scss"],
})
export class MarketingPortalCampaignOverviewComponent implements OnInit {
  @Input() socialMediaCampaign;
  public readonly environment = environment;
  constructor(private router: Router) {}

  ngOnInit() {}

  goToPayment() {
    this.router.navigateByUrl(
      "/mapAds/marketing-portal/checkout/campaign/" +
        this.socialMediaCampaign.socialMediaCampaignId
    );
  }
}
