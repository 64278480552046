import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { environment } from "environments/environment";

@Component({
  selector: "campaign-facebook-preview",
  templateUrl: "./campaign-facebook-preview.component.html",
  styleUrls: ["./campaign-facebook-preview.component.scss"],
})
export class CampaignFacebookPreviewComponent implements OnInit {
  @Input() facebookPageId: string;
  @Input() facebookPageName: string;
  @Input() displayText: string;
  @Input() link: string;
  @Input() previewMediaFiles: any;
  @Input() showFullTransparency?: boolean = true;
  @Input() templateImageId?: number = null;
  @Input() adMaterialType?: number = 1;
  public AD_MATERIAL_TYPE_UPLOAD: number = 1;
  public AD_MATERIAL_TYPE_EDITOR: number = 2;
  public readonly environment = environment;
  public hostname: string;
  public imageIndex: number = 0;
  public MAX_PREVIEW_MEDIA_SIZE = 240;
  constructor() {}

  ngOnInit() {
    this.setHostname();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("link")) {
      this.setHostname();
    }

    if (
      changes.hasOwnProperty("previewMediaFiles") ||
      changes.hasOwnProperty("adMaterialType")
    ) {
      this.imageIndex = 0;
    }
  }

  addhttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  setHostname() {
    if (this.link && this.link != "") {
      let tempLink = this.link;
      tempLink = this.addhttp(tempLink);
      try {
        this.hostname = new URL(tempLink).hostname;
        this.hostname = this.hostname.replace("www.", "");
      } catch (e) {
        this.hostname = this.link;
      }
    }
  }

  onImageLoad($event) {
    const img = new Image();
    img.src = $event.target.src;

    const target = $event.target;
    const self = this;

    img.onload = function () {
      if (img.height > img.width) {
        target.style.width = "auto";
        target.style.height = self.MAX_PREVIEW_MEDIA_SIZE + "px";
      } else {
        target.style.width = self.MAX_PREVIEW_MEDIA_SIZE + "px";
        target.style.height = "auto";
      }
    };
  }

  nextImage() {
    this.imageIndex =
      this.imageIndex + 1 >= this.previewMediaFiles.length
        ? 0
        : this.imageIndex + 1;
  }

  prevImage() {
    this.imageIndex =
      this.imageIndex === 0
        ? this.previewMediaFiles.length - 1
        : this.imageIndex - 1;
  }
}
