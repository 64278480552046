import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "image-cropper-wrapper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperComponent implements OnInit {
  @Input() imageFile: any;
  @Input() croppedImage: any;
  @Input() width?: number;
  @Input() height?: number;
  @Input() forceAspectRatio?: boolean = false;
  @Output() setCroppedImage: EventEmitter<any> = new EventEmitter(true);
  public isLoading: boolean;
  public maintainAspectRatio: boolean;
  public aspectRatio: string;
  constructor() {
    this.isLoading = true;
    this.maintainAspectRatio = true;
  }

  ngOnInit() {
    this.setAspectRatio();
  }

  setAspectRatio() {
    this.aspectRatio = (this.width / this.height).toString();
  }

  imageLoaded() {
    this.isLoading = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    let el = document.getElementById("preview-cropped-image");
    if (event.height > event.width) {
      el.style.width = "auto";
      el.style.height = "128px";
    } else {
      el.style.width = "128px";
      el.style.height = "auto";
    }

    this.setCroppedImage.emit(event.base64);
  }

  cropperReady() {
    this.maintainAspectRatio = this.forceAspectRatio;
  }
}
