import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-editor-configurator-toolset-item",
  templateUrl:
    "./marketing-portal-editor-configurator-toolset-item.component.html",
  styleUrls: [
    "./marketing-portal-editor-configurator-toolset-item.component.scss",
  ],
})
export class MarketingPortalEditorConfiguratorToolsetItemComponent
  implements OnInit
{
  @Output() onImportImage: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteImage: EventEmitter<any> = new EventEmitter();
  @Input() itemForm: FormGroup;
  @Input() modification: any;
  @Input() errors: any;
  @Input() uploadedImageIds: any;
  public isOpen: boolean = true;
  public readonly environment = environment;
  constructor() {}

  onImportImageAction(modification, type) {
    const name = modification.name + "_" + type;
    const { width, height } = modification;

    this.onImportImage.emit({ name, width, height });
  }

  ngOnInit() {}

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
