import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { UploadImageDialogComponent } from "app/shared/components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { forkJoin } from "rxjs";
import { MAX_UPLOAD_IMAGE_SIZE } from "../../../../../../../constants";

@Component({
  selector: "marketing-portal-template-editor",
  templateUrl: "./marketing-portal-template-editor.component.html",
  styleUrls: ["./marketing-portal-template-editor.component.scss"],
})
export class MarketingPortalTemplateEditorComponent implements OnInit {
  @Input() template;
  @Output() onReturn: EventEmitter<any> = new EventEmitter();
  public editorImageId: number = null;
  public editorImageUrl: string;
  public inputChanged: boolean = true;
  public itemForm: FormGroup;
  public errors: object = {};
  public uploadedImageIds: object = {};
  public onFocusImageElement: string = "";
  readonly MAX_UPLOAD_IMAGE_SIZE = MAX_UPLOAD_IMAGE_SIZE;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.buildFormGroup();
  }

  buildFormGroup() {
    this.itemForm = new FormGroup({});

    this.template.templateConfigModel.templateConfig.modifications.forEach(
      (modification) => {
        modification.elements.forEach((element) => {
          let isRequired = element.Type != "image_id";
          let label = modification.name + "_" + element.type;

          if (isRequired) {
            this.itemForm.addControl(
              label,
              new FormControl(element.value, Validators.required)
            );
          } else {
            this.itemForm.addControl(label, new FormControl(element.value));
          }
        });
      }
    );
  }

  emitFiles($event) {
    const file = $event.target.files[0];

    if (!this.hasValidFileSize(file)) {
      return;
    }

    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "1000px",
      maxHeight: "95vh",
      data: {
        file: file,
        useBackgroundRemover: false,
        useImageCropper: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const croppedFile = res.res;

        this.appLoader.open();

        await this.uploadMediaFile(croppedFile).catch((e) => {
          this.snackbarService.show(e.message, "danger");
        });

        this.appLoader.close();
      }
    });
  }

  uploadMediaFile(croppedFile) {
    const imageObservables = [
      this.crudService.uploadCampaignImageAssets(croppedFile),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(imageObservables).subscribe(
        (x) => {
          for (var i = 0; i < x.length; i++) {
            if (x[i]["body"] != null) {
              let imageId = x[i]["body"]["uploadInformation"][0]["Id"];

              this.itemForm.get(this.onFocusImageElement).setValue(imageId);
              this.addImageId(this.onFocusImageElement, imageId);
            }
          }
          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addImageId(key, id) {
    this.uploadedImageIds[key] = id;
  }

  hasValidFileSize(file) {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.MAX_UPLOAD_IMAGE_SIZE;
    if (fileSize > maxUploadSize) {
      this.snackbarService.show(
        this.translate.instant("ImageUploadMaxSizeError", {
          maxUploadSize: maxUploadSize,
        }),
        "danger"
      );
      return false;
    }

    return true;
  }
}
