import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "social-media-campaign-grid",
  templateUrl: "./social-media-campaign-grid.component.html",
  styleUrls: ["./social-media-campaign-grid.component.scss"],
})
export class SocialMediaCampaignGridComponent implements OnInit {
  @Input() socialMediaCampaigns: any;
  constructor() {}

  ngOnInit() {}
}
