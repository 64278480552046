import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "app/shared/services/snackbar.service";
import {
  MAX_UPLOAD_VIDEO_SIZE,
  MAX_UPLOAD_IMAGE_SIZE,
} from "../../../../constants";
import { CropImageDialogComponent } from "../dialogs/crop-image-dialog/crop-image-dialog.component";
import { UploadImageDialogComponent } from "../dialogs/upload-image-dialog/upload-image-dialog.component";

@Component({
  selector: "app-drag-drop-upload",
  templateUrl: "./drag-drop-upload.component.html",
  styleUrls: ["./drag-drop-upload.component.scss"],
})
export class DragDropUploadComponent implements OnInit {
  constructor(
    private snack: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {}
  files: string[] = [];
  @Input() removeBackground?: boolean = false;
  @Input() useImageCropper?: boolean = true;
  @Input() uploadType?: string = "image";
  @Output() filesDropped = new EventEmitter<string[]>();
  @Output() filesDroppedEvent = new EventEmitter<any>();
  public acceptTypes: any = {
    video: "video/mp4, video/*",
    image: "image/png, image/jpeg",
  };
  public UPLOAD_TYPE_IMAGE = "image";
  public UPLOAD_TYPE_VIDEO = "video";

  showDeleteButtons: boolean = false;
  showFilesUploaded: boolean = false;
  ngOnInit() {}

  emitFiles(event, isDragEvent) {
    const files = isDragEvent ? event : event.target.files;

    this.isImageFile()
      ? this.handleImageFiles(event, files, isDragEvent)
      : this.handleVideoFile(event, files[0], isDragEvent);
  }

  handleVideoFile(event, file, isDragEvent) {
    if (!isDragEvent) {
      event.target.value = "";
    }

    if (!this.hasValidFileSize(file)) {
      return;
    }

    this.files.push(file.name);
    this.filesDropped.emit(file);
    this.filesDroppedEvent.emit([file]);
  }

  hasValidFileSize(file) {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.getMaxUploadSize();
    if (fileSize > maxUploadSize) {
      this.snackbarService.show(
        this.translate.instant("ImageUploadMaxSizeError", {
          maxUploadSize: maxUploadSize,
        }),
        "danger"
      );
      return false;
    }

    return true;
  }

  handleImageFiles(event, files, isDragEvent) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      if (!this.hasValidFileSize(element)) {
        break;
      }

      const dialogRef = this.dialog.open(UploadImageDialogComponent, {
        width: "1000px",
        maxHeight: "95vh",
        data: {
          file: element,
          useBackgroundRemover: this.removeBackground,
          useImageCropper: this.useImageCropper,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.files.push(res.res.name);
          this.filesDropped.emit(this.files);
          this.filesDroppedEvent.emit([res.res]);
        }
      });
    }

    if (!isDragEvent) {
      event.target.value = "";
    }
  }

  isImageFile() {
    return this.uploadType === this.UPLOAD_TYPE_IMAGE;
  }

  isVideoFile() {
    return this.uploadType === this.UPLOAD_TYPE_VIDEO;
  }

  getMaxUploadSize() {
    return this.isImageFile() ? MAX_UPLOAD_IMAGE_SIZE : MAX_UPLOAD_VIDEO_SIZE;
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }
}
