import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { UploadImageDialogComponent } from "app/shared/components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { forkJoin, Subscription } from "rxjs";
import { MAX_UPLOAD_IMAGE_SIZE } from "../../../../../../constants";

@Component({
  selector: "marketing-portal-editor-configurator-toolset",
  templateUrl: "./marketing-portal-editor-configurator-toolset.component.html",
  styleUrls: ["./marketing-portal-editor-configurator-toolset.component.scss"],
})
export class MarketingPortalEditorConfiguratorToolsetComponent
  implements OnInit
{
  @ViewChild("form") form;
  @Input() templateConfigModel;
  @Output() generateImage: EventEmitter<any> = new EventEmitter();
  @Output() setUpdatedTemplateConfig: EventEmitter<any> = new EventEmitter();
  @Output() setInputChanged: EventEmitter<any> = new EventEmitter();
  @Input() inputChanged: boolean;
  @ViewChild("fileInput") fileInput: ElementRef;
  public itemForm: FormGroup;
  public errors: object = {};
  public uploadedImageIds: object = {};
  public onFocusImageElement: string = "";
  public onFocusImageWidth: number = 0;
  public onFocusImageHeight: number = 0;
  public globalColor: any;
  readonly MAX_UPLOAD_IMAGE_SIZE = MAX_UPLOAD_IMAGE_SIZE;
  public formSubscription: Subscription = null;
  public showGlobalColor: boolean = true;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.buildFormGroup();

    this.globalColor = this.templateConfigModel.templateConfig.globalColorCode;
    this.setShowGlobalColor();
  }

  ngAfterViewInit() {
    this.formSubscription = this.itemForm.valueChanges.subscribe((result) => {
      this.updateTemplateCongfig();

      this.setInputChanged.emit(true);
    });
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  setShowGlobalColor() {
    this.showGlobalColor = this.globalColor != false && this.hasColorInput();
  }

  hasColorInput() {
    let hasColorInput = false;
    this.templateConfigModel.templateConfig.modifications.forEach(
      (modification) => {
        modification.elements.forEach((element) => {
          if (element.type === "color") {
            hasColorInput = true;
          }
        });
      }
    );

    return hasColorInput;
  }

  buildFormGroup() {
    this.itemForm = new FormGroup({});

    this.templateConfigModel.templateConfig.modifications.forEach(
      (modification) => {
        modification.elements.forEach((element) => {
          let isRequired = element.Type != "image_id";
          let label = modification.name + "_" + element.type;

          if (isRequired) {
            this.itemForm.addControl(
              label,
              new FormControl(element.value, Validators.required)
            );
          } else {
            this.itemForm.addControl(label, new FormControl(element.value));
          }
        });
      }
    );
  }

  setAllColors(value) {
    this.templateConfigModel.templateConfig.modifications.forEach(
      (modification) => {
        modification.elements.forEach((element) => {
          if (element.type === "color") {
            let label = modification.name + "_" + element.type;
            this.itemForm.get(label).setValue(value);
          }
        });
      }
    );

    this.updateTemplateCongfig();
  }

  onChangeGlobalColor($event) {
    const value = $event.target.value;
    this.setAllColors(value);

    this.setInputChanged.emit(true);
  }

  onDeleteImage($event) {
    this.itemForm.get($event).setValue(null);
    delete this.uploadedImageIds[$event];
  }

  onImportImage($event) {
    const { name, height, width } = $event;

    this.onFocusImageElement = name;
    this.onFocusImageWidth = width;
    this.onFocusImageHeight = height;

    this.fileInput.nativeElement.click();
  }

  emitFiles($event) {
    const file = $event.target.files[0];
    $event.target.value = "";

    if (!this.hasValidFileSize(file)) {
      return;
    }

    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "1000px",
      maxHeight: "95vh",
      data: {
        file: file,
        useBackgroundRemover: false,
        useImageCropper: true,
        width: this.onFocusImageWidth,
        height: this.onFocusImageHeight,
        forceAspectRatio: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const croppedFile = res.res;

        this.appLoader.open();

        await this.uploadMediaFile(croppedFile).catch((e) => {
          this.snackbarService.show(e.message, "danger");
        });

        this.appLoader.close();
      }
    });
  }

  uploadMediaFile(croppedFile) {
    const imageObservables = [
      this.crudService.uploadCampaignImageAssets(croppedFile),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(imageObservables).subscribe(
        (x) => {
          for (var i = 0; i < x.length; i++) {
            if (x[i]["body"] != null) {
              let imageId = x[i]["body"]["uploadInformation"][0]["Id"];

              this.itemForm.get(this.onFocusImageElement).setValue(imageId);
              this.addImageId(this.onFocusImageElement, imageId);
            }
          }
          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addImageId(key, id) {
    this.uploadedImageIds[key] = id;
  }

  hasValidFileSize(file) {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.MAX_UPLOAD_IMAGE_SIZE;
    if (fileSize > maxUploadSize) {
      this.snackbarService.show(
        this.translate.instant("ImageUploadMaxSizeError", {
          maxUploadSize: maxUploadSize,
        }),
        "danger"
      );
      return false;
    }

    return true;
  }

  generateTemplateConfigValues(values) {
    const updatedTemplateConfig = [];

    this.templateConfigModel.templateConfig.modifications.forEach(
      (modification) => {
        const updatedModification = modification;

        modification.elements.forEach((element, i) => {
          updatedModification.elements[i].value =
            values[modification.name + "_" + element.type];
        });

        updatedTemplateConfig.push(updatedModification);
      }
    );

    return updatedTemplateConfig;
  }

  updateTemplateCongfig() {
    const values = this.itemForm.getRawValue();
    this.setUpdatedTemplateConfig.emit(
      this.generateTemplateConfigValues(values)
    );
  }

  submit() {
    this.generateImage.emit();
    this.setInputChanged.emit(false);
  }
}
