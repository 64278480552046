// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { SERVICE_ID_MAPADS } from "../../src/constants";

export const environment = {
  serviceId: SERVICE_ID_MAPADS,
  production: false,
  appProvider: "mapAds",
  backendUrl: "https://x.mapads.net/internal2/api/",
  externalSystemsUrl: "https://testsystem.mapads.net/ExternalSystems/api/",
  googleUrl: "https://testsystem.mapads.net/Google/api/",
  facebookUrl: "https://testsystem.mapads.net/Facebook/api/",
  backendTokenUrl: "https://x.mapads.net/internal2/",
  imageServerUrl: "https://testsystem.mapads.net/ImageApi/",
  ownPartnerAppId: "c3ad0bce-2ac2-47ff-a99c-f291d51b143f",
};

// export const environment = {
//   production: false,
//   apiURL: 'developmentApi'
// };
