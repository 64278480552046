import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'marketing-portal-editor-templates-sidebar',
  templateUrl: './marketing-portal-editor-templates-sidebar.component.html',
  styleUrls: ['./marketing-portal-editor-templates-sidebar.component.scss']
})
export class MarketingPortalEditorTemplatesSidebarComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() groupedTemplates;
  constructor() { }

  ngOnInit() {
  }

}
