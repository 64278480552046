import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-marketing-portal-editor-dialog",
  templateUrl: "./marketing-portal-editor-dialog.component.html",
  styleUrls: ["./marketing-portal-editor-dialog.component.scss"],
})
export class MarketingPortalEditorDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MarketingPortalEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }

  onApply($event) {
    this.dialogRef.close({ id: $event });
  }
}
