import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CrudService } from "app/views/mapAds/crud.service";
import { AddRegionDialogComponent } from "../../dialogs/add-region-dialog/add-region-dialog.component";
import * as uuid from "uuid";
import { SnackbarService } from "app/shared/services/snackbar.service";

@Component({
  selector: "campaign-regions",
  templateUrl: "./campaign-regions.component.html",
  styleUrls: ["./campaign-regions.component.scss"],
})
export class CampaignRegionsComponent implements OnInit {
  @Input() regions: any[];
  public stores: any;
  public parentStoreId;
  public isLoadingParentStore: boolean = true;
  public defaultRadius: any;
  public radiusOptions;
  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {
    this.parentStoreId = window.localStorage["storeId"];
  }

  async ngOnInit() {
    this.radiusOptions = await this.crudService
      .getSocialMediaRadius()
      .toPromise();
    this.stores = await this.crudService
      .getChildStores(this.parentStoreId)
      .toPromise();

    const firstStore = this.stores[this.stores.length - 1];
    this.defaultRadius = this.radiusOptions[0];

    this.regions.push({
      id: uuid.v4(),
      address: firstStore.address,
      radius: this.defaultRadius,
    });

    this.isLoadingParentStore = false;
  }

  openAddRegionDialog() {
    const dialogRef = this.dialog.open(AddRegionDialogComponent, {
      width: "650px",
      data: {
        stores: this.stores,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.regions.push({
          id: uuid.v4(),
          address: res.selectedRegion,
          radius: this.defaultRadius,
        });
      }
    });
  }

  removeRegion(regionId: number) {
    if (this.regions.length === 1) {
      this.snackbarService.show(
        "Bitte füge zuerst eine neue Region hinzu, bevor du eine entfernen kannst",
        "danger"
      );
      return;
    }

    const index = this.regions.findIndex((region) => region.id === regionId);
    if (index > -1) {
      this.regions.splice(index, 1);
    }
  }
}
