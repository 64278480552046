import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-editor-template-configurator",
  templateUrl: "./marketing-portal-editor-template-configurator.component.html",
  styleUrls: ["./marketing-portal-editor-template-configurator.component.scss"],
})
export class MarketingPortalEditorTemplateConfiguratorComponent
  implements OnInit
{
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() template;
  public previewImageUrl: string;
  public updatedTemplateConfig: any;
  public imageId: number;
  public inputChanged: boolean = false;
  readonly environment = environment;

  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.imageId = this.template.templateImageId;
    this.setPreviewImageUrl();
  }

  setUpdatedTemplateConfig(templateConfig) {
    this.updatedTemplateConfig = templateConfig;
  }

  setInputChanged(inputChanged) {
    this.inputChanged = inputChanged;
  }

  setPreviewImageUrl() {
    this.previewImageUrl = `${this.environment.imageServerUrl}/api/Public/GetMediaAsset?id=${this.imageId}`;
  }

  async generateImage(apply = false) {
    const data = {
      templateId: this.template.templateId,
      templateExternalId: this.template.templateExternalId,
      hasGlobalColor: false,
      globalColorCode:
        this.template.templateConfigModel.templateConfig.globalColorCode,
      templateConfigs: this.updatedTemplateConfig,
    };

    this.appLoader.open();

    await this.crudService
      .createTemplateBasedImage(data)
      .toPromise()
      .then((res: number) => {
        this.imageId = res;
        this.setPreviewImageUrl();

        if (apply) {
          this.onApply.emit(this.imageId);
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  onApplyTemplateImage() {
    if (this.inputChanged) {
      this.generateImage(true);
    } else {
      this.onApply.emit(this.imageId);
    }
  }
}
