import { Component, Input, OnInit } from "@angular/core";
import { environment } from "environments/environment";

@Component({
  selector: "social-media-campaign-card",
  templateUrl: "./social-media-campaign-card.component.html",
  styleUrls: ["./social-media-campaign-card.component.scss"],
})
export class SocialMediaCampaignCardComponent implements OnInit {
  @Input() socialMediaCampaign: any;
  public previewImageUrl: string;
  public readonly environment = environment;
  constructor() {}

  ngOnInit() {
    this.previewImageUrl =
      environment.imageServerUrl +
      "/api/Public/GetMediaAsset?id=" +
      this.socialMediaCampaign.mediaAssets[0].mediaAssetId;
  }
}
