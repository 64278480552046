import { Component, OnInit } from "@angular/core";

@Component({
  selector: "smartphone",
  templateUrl: "./smartphone.component.html",
  styleUrls: ["./smartphone.component.scss"],
})
export class SmartphoneComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
