import { Component, Input, OnInit } from "@angular/core";
import { MPStatus } from "app/model/marketing-portal/MPStatus";

@Component({
  selector: "marketing-portal-status",
  templateUrl: "./marketing-portal-status.component.html",
  styleUrls: ["./marketing-portal-status.component.scss"],
})
export class MarketingPortalStatusComponent implements OnInit {
  @Input() status: MPStatus;
  public colors: object = {
    1: {
      // Aktiv
      backgroundColor: "background-primary-light",
      color: "primary-color",
    },
    2: {
      // Entwurf
      backgroundColor: "background-purple-200",
      color: "purple-600-color",
    },
    3: {
      // Geplant
      backgroundColor: "background-purple-200",
      color: "purple-600-color",
    },
    4: {
      // Zahlung nicht erfolgreich
      backgroundColor: "background-danger-light",
      color: "danger-color",
    },
    5: {
      // Beendet
      backgroundColor: "background-purple-200",
      color: "purple-600-color",
    },
    6: {
      // Fehler
      backgroundColor: "background-danger-light",
      color: "danger-color",
    },
    7: {
      // In Prüfung
      backgroundColor: "background-purple-200",
      color: "purple-600-color",
    },
    8: {
      // Abgelenht
      backgroundColor: "background-danger-light",
      color: "danger-color",
    },
  };
  constructor() {}

  ngOnInit() {}
}
