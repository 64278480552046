import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-templates-card",
  templateUrl: "./marketing-portal-templates-card.component.html",
  styleUrls: ["./marketing-portal-templates-card.component.scss"],
})
export class MarketingPortalTemplatesCardComponent implements OnInit {
  @Input() marketingPortalTemplate;
  @Input() showCategory: boolean = true;
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
