import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "campaign-channel-action-card",
  templateUrl: "./campaign-channel-action-card.component.html",
  styleUrls: ["./campaign-channel-action-card.component.scss"],
})
export class CampaignChannelActionCardComponent implements OnInit {
  @Input() marketingPortalChannel: any;
  constructor() {}

  ngOnInit() {}
}
