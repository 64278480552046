import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { AdPreview } from "app/model/AdPreview";

@Component({
  selector: "app-facebook-campaign-ad-preview",
  templateUrl: "./facebook-campaign-ad-preview.component.html",
  styleUrls: ["./facebook-campaign-ad-preview.component.scss"],
})
export class FacebookCampaignAdPreviewComponent implements OnInit {
  private adPreviews: AdPreview[];
  public isLoading: boolean;
  public iFrameLoading: boolean;
  public values: Array<string>;
  public index: number;
  constructor(
    public dialogRef: MatDialogRef<FacebookCampaignAdPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.values = [];
    this.isLoading = false;
    this.iFrameLoading = true;
    this.index = 0;
  }

  ngOnInit() {
    this.setAdPreviews();

    this.generatePreviewFrames();
  }

  setAdPreviews() {
    this.adPreviews = this.data.adPreviews || [];
  }

  generatePreviewFrames() {
    this.adPreviews.forEach((adPreview) => {
      const src = adPreview.IFrame.match('src="([a-zA-Z0-9:/._?=;\\-&]+)"');
      try {
        adPreview.Src = this.sanitizer.bypassSecurityTrustResourceUrl(
          src[1].replace("&amp;", "&")
        );
        this.adPreviews.push(adPreview);
        this.values.push(adPreview.Name);
      } catch {}
    });
  }

  onNext() {
    this.index += 1;
    this.iFrameLoading = true;
  }

  onBack() {
    this.index -= 1;
    this.iFrameLoading = true;
  }

  toIndex(value) {
    this.index = value.index;
    this.iFrameLoading = true;
  }

  onClose() {
    this.dialogRef.close();
  }
}
