import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'marketing-portal-editor-modification-icon',
  templateUrl: './marketing-portal-editor-modification-icon.component.html',
  styleUrls: ['./marketing-portal-editor-modification-icon.component.scss']
})
export class MarketingPortalEditorModificationIconComponent implements OnInit {
  @Input() configTypeName: string;
  public configTypeImageMap = {
    Text: "title",
    Shape: "shape_line",
    Image: "image",
    Video: "videocam",
    Global: "public"
  }
  constructor() { }

  ngOnInit() {
  }

}
