import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import {
  MatTreeModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatBadgeModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatStepper,
  MatHorizontalStepper,
  MatStepperModule,
  MatTable,
  MatTableModule,
  MatExpansionModule,
  MatInputModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatSlider,
  MatSliderModule,
} from "@angular/material";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { SidebarSideComponent } from "./components/sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./components/header-top/header-top.component";
import { SidebarTopComponent } from "./components/sidebar-top/sidebar-top.component";

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from "./components/customizer/customizer.component";

// ALL TIME REQUIRED
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "./services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";
import { FilterSearchComponent } from "./components/filter-search/filter-search.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragDropUploadComponent } from "./components/drag-drop-upload/drag-drop-upload.component";
import { DragDropDirective } from "./components/drag-drop-upload/drag-drop.directive";
import { SupportPopoverButtonComponent } from "./components/support/support-popover-button/support-popover-button.component";
import { SupportPopoverComponent } from "./components/dialogs/support-popover/support-popover.component";
import { CreatePromotionDialogComponent } from "./components/dialogs/promotions/create-promotion-dialog/create-promotion-dialog.component";
import { StoreSelectionTreeComponent } from "./components/trees/store-selection-tree/store-selection-tree.component";
import { CreatePromotionFormComponent } from "./components/forms/promotions/create-promotion-form/create-promotion-form.component";
import { SearchGtinPromotionFormComponent } from "./components/forms/promotions/search-gtin-promotion-form/search-gtin-promotion-form.component";
import { PopoverComponent } from "./components/notifications/popover/popover.component";
import { PopoverPasswordCheckComponent } from "./components/notifications/popover/popover-password-check/popover-password-check.component";
import { PromotionPreviewDialogComponent } from "./components/dialogs/promotions/promotion-preview-dialog/promotion-preview-dialog.component";
import { PopoverTcontractInfoComponent } from "./components/notifications/popover/popover-tcontract-info/popover-tcontract-info.component";
import { GooglePreviewComponent } from "./components/previews/google-preview/google-preview.component";
import { InstagramPreviewComponent } from "./components/previews/instagram-preview/instagram-preview.component";
import { FacebookPreviewComponent } from "./components/previews/facebook-preview/facebook-preview.component";
import { YoutubePreviewComponent } from "./components/previews/youtube-preview/youtube-preview.component";
import { GoogleLocalInventoryPreviewComponent } from "./components/previews/google-local-inventory-preview/google-local-inventory-preview.component";

// DIRECTIVES
import { FontSizeDirective } from "./directives/font-size.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AppDropdownDirective } from "./directives/dropdown.directive";
import { DropdownAnchorDirective } from "./directives/dropdown-anchor.directive";
import { DropdownLinkDirective } from "./directives/dropdown-link.directive";
import { EgretSideNavToggleDirective } from "./directives/egret-side-nav-toggle.directive";

// PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { GetValueByKeyPipe } from "./pipes/get-value-by-key.pipe";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { GetStoreNameByIdPipe } from "./pipes/stores/get-store-name-by-id.pipe";
import { GetTemplateNameByIdPipe } from "./pipes/templates/get-template-name-by-id.pipe";
import { GetTemplateScreenshotByIdPipe } from "./pipes/templates/get-template-screenshot-by-id.pipe";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { LayoutService } from "./services/layout.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { ButtonLoadingComponent } from "./components/button-loading/button-loading.component";
import { SearchModule } from "./search/search.module";
import { ChatService } from "./components/notifications/notifications.service";
import { FacebookPageConnectPopupComponent } from "app/views/mapAds/settings/facebook-page-connect-popup/facebook-page-connect-popup.component";
import { ErrorMessageHelperComponent } from "./components/error-message-helper/error-message-helper.component";
import { SubchannelCardComponent } from "./components/cards/subchannel-card/subchannel-card.component";
import { SubchannelGridComponent } from "./components/grids/subchannel-grid/subchannel-grid.component";
import { AdditionalChannelPackagesInfoDialogComponent } from "./components/dialogs/additional-channel-packages-info-dialog/additional-channel-packages-info-dialog.component";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { PromotionSuccessfullyCreatedDialogComponent } from "./components/dialogs/promotions/promotion-successfully-created-dialog/promotion-successfully-created-dialog.component";
import { BasicChannelCardComponent } from "./components/cards/basic-channel-card/basic-channel-card.component";
import { BasicChannelsGridComponent } from "./components/grids/basic-channels-grid/basic-channels-grid.component";
import { ExternalSystemsGridComponent } from "./components/grids/external-systems-grid/external-systems-grid.component";
import { ExternalSystemCardComponent } from "./components/cards/external-system-card/external-system-card.component";
import { UnblockPopupFirefoxInstructionComponent } from "./components/instructions/unblock-popup-firefox-instruction/unblock-popup-firefox-instruction.component";
import { PopupBlockedDialogComponent } from "./components/dialogs/popup-blocked-dialog/popup-blocked-dialog.component";
import { UnblockPopupSafariInstructionComponent } from "./components/instructions/unblock-popup-safari-instruction/unblock-popup-safari-instruction.component";
import { AllowBrowserPopupInstructionsComponent } from "./components/instructions/allow-browser-popup-instructions/allow-browser-popup-instructions.component";
import { FacebookAdsPreviewComponent } from "./components/dialogs/ads/facebook-ads-preview/facebook-ads-preview.component";
import { PanelComponent } from "./components/panel/panel.component";
import { PanelHeaderComponent } from "./components/panel/panel-header/panel-header.component";
import { SelectArrowComponent } from "./components/partials/select-arrow/select-arrow.component";
import { SelectCirclesComponent } from "./components/select-circles/select-circles.component";
import { PanelContentComponent } from "./components/panel/panel-content/panel-content.component";
import { ProgressCirclesComponent } from "./components/progress-circles/progress-circles.component";
import { AnimationCheckmarkCircleComponent } from "./components/partials/animation-checkmark-circle/animation-checkmark-circle.component";
import { StatusSelectCirclesComponent } from "./components/partials/status-select-circles/status-select-circles.component";
import { GoogleStorecodesInstructionsComponent } from "./components/instructions/google-storecodes-instructions/google-storecodes-instructions.component";
import { ChannelInfoDialogComponent } from "./components/dialogs/channel-info-dialog/channel-info-dialog.component";
import { CropImageDialogComponent } from "./components/dialogs/crop-image-dialog/crop-image-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ActiveExternalSystemsGridComponent } from "./components/grids/active-external-systems-grid/active-external-systems-grid.component";
import { ActiveExternalSystemCardComponent } from "./components/cards/active-external-system-card/active-external-system-card.component";
import { ContentBoxComponent } from "./components/content-box/content-box/content-box.component";
import { ContentBoxItemComponent } from "./components/content-box/content-box-item/content-box-item.component";
import { ContentBoxTitleComponent } from "./components/content-box/content-box-title/content-box-title.component";
import { ContentBoxItemLabelComponent } from "./components/content-box/content-box-item-label/content-box-item-label.component";
import { ContentBoxItemValueComponent } from "./components/content-box/content-box-item-value/content-box-item-value.component";
import { ContentBoxLabelValueComponent } from "./components/content-box/content-box-label-value/content-box-label-value.component";
import { InlineIconMessageComponent } from "./components/messages/inline-icon-message/inline-icon-message.component";
import { CustomActionIconMessageComponent } from "./components/messages/custom-action-icon-message/custom-action-icon-message.component";
import { SuccessAnimationActionMessageComponent } from "./components/messages/success-animation-action-message/success-animation-action-message.component";
import { InlineActionIconMessageComponent } from "./components/messages/inline-action-icon-message/inline-action-icon-message.component";
import { AddExternalSystemFormComponent } from "./components/forms/external-systems/add-external-system-form/add-external-system-form.component";
import { ErrorActionMessageComponent } from "./components/messages/error-action-message/error-action-message.component";
import { InfoActionMessageComponent } from "./components/messages/info-action-message/info-action-message.component";
import { SelectMenuComponent } from "./components/select-menu/select-menu.component";
import { SelectMenuItemComponent } from "./components/select-menu/select-menu-item/select-menu-item.component";
import { GridComponent } from "./components/grids/grid/grid.component";
import { SelectMultipleMenuComponent } from "./components/select/select-multiple-menu/select-multiple-menu.component";
import { TrendArrowComponent } from "./components/partials/trend-arrow/trend-arrow.component";
import { ChartsModule } from "ng2-charts";
import { RankingChartComponent } from "./components/statistics/ranking-chart/ranking-chart.component";
import { ChannelHeatMapComponent } from "./components/maps/channel-heat-map/channel-heat-map.component";
import { HeatMapSidebarFilterComponent } from "./components/partials/heat-map-sidebar-filter/heat-map-sidebar-filter.component";
import { StoreLocationSelectListComponent } from "./components/lists/stores/store-location-select-list/store-location-select-list.component";
import { StoreDetailCardComponent } from "./components/cards/store-detail-card/store-detail-card.component";
import { ZipcodeImpressionsSelectListComponent } from "./components/lists/zipcodes/zipcode-impressions-select-list/zipcode-impressions-select-list.component";
import { HeatMapSettingsComponent } from "./components/partials/heat-map-settings/heat-map-settings.component";
import { ProductActivitiesTimelineComponent } from "./components/partials/product-activities-timeline/product-activities-timeline.component";
import { NoDataMessageComponent } from "./components/messages/no-data-message/no-data-message.component";
import { PageHeaderComponent } from "./components/partials/page-header/page-header.component";
import { VerticalDividerComponent } from "./components/partials/divider/vertical-divider/vertical-divider.component";
import { IconMessageComponent } from "./components/messages/icon-message/icon-message.component";
import { ErrorIconMessageComponent } from "./components/messages/icon-message/error-icon-message/error-icon-message.component";
import { InfoIconMessageComponent } from "./components/messages/icon-message/info-icon-message/info-icon-message.component";
import { SuccessIconMessageComponent } from "./components/messages/icon-message/success-icon-message/success-icon-message.component";
import { HorizontalDividerComponent } from "./components/partials/divider/horizontal-divider/horizontal-divider.component";
import { CreateStoreFormComponent } from "./components/forms/stores/create-store-form/create-store-form.component";
import { AddActionBoxComponent } from "./components/ui/item-action-box/add-action-box/add-action-box.component";
import { ViewActionBoxComponent } from "./components/ui/item-action-box/view-action-box/view-action-box.component";
import { SelectItemActionBoxComponent } from "./components/ui/select/select-item-action-box/select-item-action-box.component";
import { SelectActionBoxComponent } from "./components/ui/item-action-box/select-action-box/select-action-box.component";
import { StepSliderComponent } from "./components/ui/select/step-slider/step-slider.component";
import { RadiusMapComponent } from "./components/maps/radius-map/radius-map.component";
import { SuccessActionIconMessageComponent } from "./components/messages/success-action-icon-message/success-action-icon-message.component";
import { PillComponent } from "./components/partials/pill/pill.component";
import { StoresMapComponent } from "./components/maps/stores-map/stores-map.component";
import { CreateGroupFormComponent } from "./components/forms/groups/create-group-form/create-group-form.component";
import { StoreTreeComponent } from "./components/trees/store-tree/store-tree.component";
import { AddNewActionMessageComponent } from "./components/messages/add-new-action-message/add-new-action-message.component";
import { TrendValueComponent } from "./components/partials/trend-value/trend-value.component";
import { LocalizedDatePipe } from "./pipes/localized-date.pipe";
import { ActivitiesTimelineComponent } from "./components/partials/activities-timeline/activities-timeline.component";
import { ImpressionsDifferenceComponent } from "./components/partials/impressions-difference/impressions-difference.component";
import { UploadImageDialogComponent } from "./components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { ImageBackgroundRemoverComponent } from "./components/image/image-background-remover/image-background-remover.component";
import { ImageCropperComponent } from "./components/image/image-cropper/image-cropper.component";
import { SelectImageFromSelectionComponent } from "./components/select/select-image-from-selection/select-image-from-selection.component";
import { ExternalSystemSuccessfullyConnectedDialogComponent } from "./components/dialogs/external-system-successfully-connected-dialog/external-system-successfully-connected-dialog.component";
import { ColorBoxComponent } from "./components/partials/color-box/color-box.component";
import { CorporateColorSelectorDialogComponent } from "./components/dialogs/corporate-color-selector-dialog/corporate-color-selector-dialog.component";
import { VideoTemplateSelectorDialogComponent } from "./components/dialogs/video-template-selector-dialog/video-template-selector-dialog.component";
import { VideoTemplateGridComponent } from "./components/grids/video-template-grid/video-template-grid.component";
import { VideoTemplateCardComponent } from "./components/cards/video-template-card/video-template-card.component";
import { SelectedVideoTemplateComponent } from "./components/partials/selected-video-template/selected-video-template.component";
import { ReviewYourVideoTemplateDialogComponent } from "./components/dialogs/review-your-video-template-dialog/review-your-video-template-dialog.component";
import { NoImagesUploadedConfirmationDialogComponent } from "./components/dialogs/no-images-uploaded-confirmation-dialog/no-images-uploaded-confirmation-dialog.component";
import { MaRoundedBasicLogoDarkComponent } from "./ma/logo/ma-rounded-basic-logo-dark/ma-rounded-basic-logo-dark.component";
import { MaCardCollapseContentComponent } from "./ma/cards/ma-card-collapse-content/ma-card-collapse-content.component";
import { MaCardComponent } from "./ma/cards/ma-card/ma-card.component";
import { MaCardContentComponent } from "./ma/cards/ma-card-content/ma-card-content.component";
import { MaCardHeaderComponent } from "./ma/cards/ma-card-header/ma-card-header.component";
import { MaCardHeaderTitleComponent } from "./ma/cards/ma-card-header-title/ma-card-header-title.component";
import { MaMultipleCardsComponent } from "./ma/cards/ma-multiple-cards/ma-multiple-cards.component";
import { MaIconPillListComponent } from "./ma/lists/ma-icon-pill-list/ma-icon-pill-list.component";
import { MaShapeCircleComponent } from "./ma/shapes/ma-shape-circle/ma-shape-circle.component";
import { MaShapeCircleOutlineComponent } from "./ma/shapes/ma-shape-circle-outline/ma-shape-circle-outline.component";
import { MaShapeRectangleComponent } from "./ma/shapes/ma-shape-rectangle/ma-shape-rectangle.component";
import { MaShapeRectangleOutlineComponent } from "./ma/shapes/ma-shape-rectangle-outline/ma-shape-rectangle-outline.component";
import { MaShapeTriangleComponent } from "./ma/shapes/ma-shape-triangle/ma-shape-triangle.component";
import { MaSnackbarComponent } from "./components/partials/ma-snackbar/ma-snackbar.component";
import { GoogleServiceInfoGuideComponent } from "./components/guides/google-service-info-guide/google-service-info-guide.component";
import { MaMessageComponent } from "./components/messages/ma-message/ma-message.component";
import { MaAccordionComponent } from "./ma/accordian/ma-accordion/ma-accordion.component";
import { SuccessTrophyMessageComponent } from "./ma/notification/success-trophy-message/success-trophy-message.component";
import { FacebookServiceInfoGuideComponent } from "./components/guides/facebook-service-info-guide/facebook-service-info-guide.component";
import { InstagramServiceInfoGuideComponent } from "./components/guides/instagram-service-info-guide/instagram-service-info-guide.component";
import { YoutubeServiceInfoGuideComponent } from "./components/guides/youtube-service-info-guide/youtube-service-info-guide.component";
import { MaBackgroundShapesDarkComponent } from "./ma/shapes/ma-background-shapes-dark/ma-background-shapes-dark.component";
import { MaBackgroundShapesLightComponent } from "./ma/shapes/ma-background-shapes-light/ma-background-shapes-light.component";
import { MaRoundedBasicLogoLightComponent } from "./ma/logo/ma-rounded-basic-logo-light/ma-rounded-basic-logo-light.component";
import { ChannelPreferencesPanelGridComponent } from "./components/grids/channel-preferences-panel-grid/channel-preferences-panel-grid.component";
import { ChannelPreferencesPanelCardComponent } from "./components/cards/channel-preferences-panel-card/channel-preferences-panel-card.component";
import { KeysPipe } from "./pipes/keys.pipe";
import { WeeklyChannelHistoryCardComponent } from "./components/cards/weekly-channel-history-card/weekly-channel-history-card.component";
import { FutureChannelHistoryCardComponent } from "./components/cards/future-channel-history-card/future-channel-history-card.component";
import { GoogleAccountInformationComponent } from "./components/account/google-account-information/google-account-information.component";
import { FacebookAccountInformationComponent } from "./components/account/facebook-account-information/facebook-account-information.component";
import { YoutubeAccountInformationComponent } from "./components/account/youtube-account-information/youtube-account-information.component";
import { InstagramAccountInformationComponent } from "./components/account/instagram-account-information/instagram-account-information.component";
import { ContentBoardComponent } from "./components/partials/content-board/content-board.component";
import { TipsComponent } from "./components/partials/tips/tips.component";
import { CampaignChannelActionCardComponent } from "./components/cards/campaign-channel-action-card/campaign-channel-action-card.component";
import { CampaignRegionsComponent } from "./components/partials/campaign-regions/campaign-regions.component";
import { CampaignRegionItemComponent } from "./components/partials/campaign-region-item/campaign-region-item.component";
import { AddRegionDialogComponent } from "./components/dialogs/add-region-dialog/add-region-dialog.component";
import { CampaignFacebookPreviewComponent } from "./components/previews/campaign-facebook-preview/campaign-facebook-preview.component";
import { SmartphoneComponent } from "./components/previews/smartphone/smartphone.component";
import { SmartphoneIphoneXComponent } from "./components/previews/smartphone-iphone-x/smartphone-iphone-x.component";
import { SocialMediaCampaignGridComponent } from "./components/grids/social-media-campaign-grid/social-media-campaign-grid.component";
import { SocialMediaCampaignCardComponent } from "./components/cards/social-media-campaign-card/social-media-campaign-card.component";
import { MarketingPortalEditorDialogComponent } from "./components/dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalTemplatesGridComponent } from "./components/grids/marketing-portal-templates-grid/marketing-portal-templates-grid.component";
import { MarketingPortalTemplatesCardComponent } from "./components/cards/marketing-portal-templates-card/marketing-portal-templates-card.component";
import { MarketingPortalTemplateEditorComponent } from "./components/partials/marketing-portal/marketing-portal/marketing-portal-template-editor/marketing-portal-template-editor.component";
import { MarketingPortalEditorComponent } from "./components/marketing-portal-editor/marketing-portal-editor.component";
import { MarketingPortalEditorHeaderComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-header/marketing-portal-editor-header.component";
import { MarketingPortalEditorTemplateConfiguratorComponent } from "./components/marketing-portal-editor/marketing-portal-editor-template-configurator/marketing-portal-editor-template-configurator.component";
import { MarketingPortalEditorTemplatesComponent } from "./components/marketing-portal-editor/marketing-portal-editor-templates/marketing-portal-editor-templates.component";
import { MarketingPortalEditorTemplatesSidebarComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-templates-sidebar/marketing-portal-editor-templates-sidebar.component";
import { MarketingPortalEditorTemplatesGroupComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-templates-group/marketing-portal-editor-templates-group.component";
import { MarketingPortalEditorSidebarComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-sidebar/marketing-portal-editor-sidebar.component";
import { MarketingPortalEditorContentComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-content/marketing-portal-editor-content.component";
import { MarketingPortalEditorConfiguratorToolsetComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-configurator-toolset/marketing-portal-editor-configurator-toolset.component";
import { MarketingPortalEditorConfiguratorToolsetItemComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-configurator-toolset-item/marketing-portal-editor-configurator-toolset-item.component";
import { MarketingPortalEditorModificationIconComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-modification-icon/marketing-portal-editor-modification-icon.component";
import { MarketingPortalEditorGlobalToolsetComponent } from "./components/marketing-portal-editor/partials/marketing-portal-editor-global-toolset/marketing-portal-editor-global-toolset.component";
import { MarketingPortalCampaignOverviewComponent } from "./components/partials/marketing-portal/marketing-portal-campaign-overview/marketing-portal-campaign-overview.component";
import { MarketingPortalStatusComponent } from "./components/partials/marketing-portal/marketing-portal-status/marketing-portal-status.component";
import { FacebookCampaignAdPreviewComponent } from "./components/dialogs/ads/facebook-campaign-ad-preview/facebook-campaign-ad-preview.component";
import { LinebreaksPipe } from "./pipes/linebreaks.pipe";

/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  LinebreaksPipe,
  CurrencyPipe,
  GetStoreNameByIdPipe,
  GetTemplateNameByIdPipe,
  GetTemplateScreenshotByIdPipe,
  KeysPipe,
  LocalizedDatePipe,
  ExcerptPipe,
  DateFormatPipe,
  GetValueByKeyPipe,
  FilterSearchComponent,
  FacebookPageConnectPopupComponent,
  ErrorMessageHelperComponent,
  DragDropUploadComponent,
  DragDropDirective,
  SupportPopoverButtonComponent,
  SupportPopoverComponent,
  CreatePromotionDialogComponent,
  StoreSelectionTreeComponent,
  CreatePromotionFormComponent,
  SearchGtinPromotionFormComponent,
  PopoverComponent,
  PopoverPasswordCheckComponent,
  PromotionPreviewDialogComponent,
  PopoverTcontractInfoComponent,
  GooglePreviewComponent,
  InstagramPreviewComponent,
  FacebookPreviewComponent,
  YoutubePreviewComponent,
  GoogleLocalInventoryPreviewComponent,
  PromotionSuccessfullyCreatedDialogComponent,
  BasicChannelCardComponent,
  BasicChannelsGridComponent,
  ExternalSystemsGridComponent,
  ExternalSystemCardComponent,
  UnblockPopupSafariInstructionComponent,
  PopupBlockedDialogComponent,
  AllowBrowserPopupInstructionsComponent,
  FacebookAdsPreviewComponent,
  PanelComponent,
  PanelHeaderComponent,
  PanelContentComponent,
  ProgressCirclesComponent,
  SelectCirclesComponent,
  AnimationCheckmarkCircleComponent,
  SelectArrowComponent,
  StatusSelectCirclesComponent,
  GoogleStorecodesInstructionsComponent,
  SubchannelCardComponent,
  SubchannelGridComponent,
  AdditionalChannelPackagesInfoDialogComponent,
  ChannelInfoDialogComponent,
  ExternalSystemSuccessfullyConnectedDialogComponent,
  CropImageDialogComponent,
  UploadImageDialogComponent,
  UnblockPopupFirefoxInstructionComponent,
  ActiveExternalSystemsGridComponent,
  ActiveExternalSystemCardComponent,
  ContentBoxComponent,
  ContentBoxItemComponent,
  ContentBoxTitleComponent,
  ContentBoxItemLabelComponent,
  ContentBoxItemValueComponent,
  ContentBoxLabelValueComponent,
  InlineIconMessageComponent,
  CustomActionIconMessageComponent,
  SuccessAnimationActionMessageComponent,
  InlineActionIconMessageComponent,
  AddExternalSystemFormComponent,
  ErrorActionMessageComponent,
  InfoActionMessageComponent,
  SelectMenuComponent,
  SelectMenuItemComponent,
  GridComponent,
  SelectMultipleMenuComponent,
  TrendArrowComponent,
  RankingChartComponent,
  ChannelHeatMapComponent,
  HeatMapSidebarFilterComponent,
  StoreLocationSelectListComponent,
  StoreDetailCardComponent,
  ZipcodeImpressionsSelectListComponent,
  HeatMapSettingsComponent,
  ProductActivitiesTimelineComponent,
  NoDataMessageComponent,
  PageHeaderComponent,
  VerticalDividerComponent,
  HorizontalDividerComponent,
  IconMessageComponent,
  ErrorIconMessageComponent,
  InfoIconMessageComponent,
  SuccessIconMessageComponent,
  CreateStoreFormComponent,
  AddActionBoxComponent,
  ViewActionBoxComponent,
  SelectItemActionBoxComponent,
  SelectActionBoxComponent,
  StepSliderComponent,
  RadiusMapComponent,
  SuccessActionIconMessageComponent,
  PillComponent,
  StoresMapComponent,
  CreateGroupFormComponent,
  StoreTreeComponent,
  AddNewActionMessageComponent,
  TrendValueComponent,
  ActivitiesTimelineComponent,
  ImpressionsDifferenceComponent,
  ImageCropperComponent,
  ImageBackgroundRemoverComponent,
  SelectImageFromSelectionComponent,
  ColorBoxComponent,
  CorporateColorSelectorDialogComponent,
  VideoTemplateSelectorDialogComponent,
  VideoTemplateGridComponent,
  VideoTemplateCardComponent,
  SelectedVideoTemplateComponent,
  ReviewYourVideoTemplateDialogComponent,
  NoImagesUploadedConfirmationDialogComponent,
  MaRoundedBasicLogoDarkComponent,
  MaRoundedBasicLogoLightComponent,
  MaMultipleCardsComponent,
  MaCardCollapseContentComponent,
  MaCardComponent,
  MaCardContentComponent,
  MaCardHeaderComponent,
  MaCardHeaderTitleComponent,
  MaIconPillListComponent,
  MaShapeCircleComponent,
  MaShapeCircleOutlineComponent,
  MaShapeRectangleComponent,
  MaShapeRectangleOutlineComponent,
  MaShapeTriangleComponent,
  MaSnackbarComponent,
  GoogleServiceInfoGuideComponent,
  MaMessageComponent,
  MaAccordionComponent,
  SuccessTrophyMessageComponent,
  FacebookServiceInfoGuideComponent,
  InstagramServiceInfoGuideComponent,
  YoutubeServiceInfoGuideComponent,
  MaBackgroundShapesDarkComponent,
  MaBackgroundShapesLightComponent,
  ChannelPreferencesPanelGridComponent,
  ChannelPreferencesPanelCardComponent,
  WeeklyChannelHistoryCardComponent,
  FutureChannelHistoryCardComponent,
  GoogleAccountInformationComponent,
  FacebookAccountInformationComponent,
  YoutubeAccountInformationComponent,
  InstagramAccountInformationComponent,
  ContentBoardComponent,
  TipsComponent,
  CampaignChannelActionCardComponent,
  CampaignRegionsComponent,
  CampaignRegionItemComponent,
  AddRegionDialogComponent,
  CampaignFacebookPreviewComponent,
  SmartphoneComponent,
  SmartphoneIphoneXComponent,
  SocialMediaCampaignGridComponent,
  SocialMediaCampaignCardComponent,
  MarketingPortalEditorDialogComponent,
  MarketingPortalTemplatesGridComponent,
  MarketingPortalTemplatesCardComponent,
  MarketingPortalTemplateEditorComponent,
  MarketingPortalEditorComponent,
  MarketingPortalEditorHeaderComponent,
  MarketingPortalEditorTemplateConfiguratorComponent,
  MarketingPortalEditorTemplatesComponent,
  MarketingPortalEditorTemplatesSidebarComponent,
  MarketingPortalEditorTemplatesGroupComponent,
  MarketingPortalEditorSidebarComponent,
  MarketingPortalEditorContentComponent,
  MarketingPortalEditorConfiguratorToolsetComponent,
  MarketingPortalEditorConfiguratorToolsetItemComponent,
  MarketingPortalEditorModificationIconComponent,
  MarketingPortalEditorGlobalToolsetComponent,
  MarketingPortalCampaignOverviewComponent,
  MarketingPortalStatusComponent,
  FacebookCampaignAdPreviewComponent,
];

@NgModule({
  imports: [
    MatTreeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    SearchModule,
    PerfectScrollbarModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    DragDropModule,
    ChartsModule,
    MatSliderModule,
    ImageCropperModule,
  ],
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    FacebookPageConnectPopupComponent,
    SupportPopoverComponent,
    CreatePromotionDialogComponent,
    PromotionPreviewDialogComponent,
    PromotionSuccessfullyCreatedDialogComponent,
    PopupBlockedDialogComponent,
    FacebookAdsPreviewComponent,
    AdditionalChannelPackagesInfoDialogComponent,
    ChannelInfoDialogComponent,
    ExternalSystemSuccessfullyConnectedDialogComponent,
    CropImageDialogComponent,
    UploadImageDialogComponent,
    CorporateColorSelectorDialogComponent,
    VideoTemplateSelectorDialogComponent,
    ReviewYourVideoTemplateDialogComponent,
    NoImagesUploadedConfirmationDialogComponent,
    AddRegionDialogComponent,
    MarketingPortalEditorDialogComponent,
    FacebookCampaignAdPreviewComponent,
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
    ChatService,

    // LandingPageService
  ],
  declarations: classesToInclude,
  exports: classesToInclude,
})
export class SharedModule {}
