import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-editor",
  templateUrl: "./marketing-portal-editor.component.html",
  styleUrls: ["./marketing-portal-editor.component.scss"],
})
export class MarketingPortalEditorComponent implements OnInit {
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Output() onCloseWindow: EventEmitter<any> = new EventEmitter();
  public selectedTemplate: number;
  constructor() {}

  ngOnInit() {}

  onSelectTemplate(res) {
    this.selectedTemplate = res.template;
  }

  onDeselectTemplate() {
    this.selectedTemplate = null;
  }

  onClose() {
    this.onCloseWindow.emit();
  }
}
